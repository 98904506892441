import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled'
import { resetExpandedFAQ, setAllExpandedFAQ } from '../../actions/displayChanges';

const ButtonListItem = styled.li`
    text-align: right;
`;

const ExpandCollapseAllButton = styled.button`
    text-decoration: none;
    padding: 0.5rem 0.75rem;
    background-color: ${props => props.theme.colors.highlight};
    color: ${props => props.theme.colors.text};
    border: 0.063rem solid transparent;
    transition: all 0.2s ease-in;

    &:hover {
        background-color: #fff;
        border-color: ${props => props.theme.colors.highlight};
        color: ${props => props.theme.colors.secondary};
        cursor: pointer;
    }
`;


const ExpandCollapseAll = ({ numberOfFAQ }) => {

    const dispatch = useDispatch();

    const numberOfExpandedFAQ = useSelector(state => state.displayChanges[0].numberOfExpandedFAQ);

    const toggleFAQDisplay = () => {
        if (numberOfExpandedFAQ === numberOfFAQ) {
            dispatch(resetExpandedFAQ())
        } else {
            dispatch(setAllExpandedFAQ(numberOfFAQ))
        }
    }

    return (
        <ButtonListItem>
            <ExpandCollapseAllButton onClick={toggleFAQDisplay}>
                {numberOfExpandedFAQ === numberOfFAQ ? 'Collapse All' : 'Expand All'}
            </ExpandCollapseAllButton>
        </ButtonListItem>
    )
}

export default ExpandCollapseAll;