import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { incrementExpandedFAQ, decrementExpandedFAQ } from '../../actions/displayChanges';

const QuestionListItem = styled.li`

`;

const ExpandCollapseButton = styled.button`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;
    padding: 0 0 0.625rem 0;
    border-bottom: 0.063rem solid ${props => props.theme.colors.primary};
    font-size: 1.5rem;
    font-weight: 400;
    text-align: left;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 500px) {
        font-size: 1.25rem;
        align-items: center;
    }
`;

const Answer = styled.p`
    padding-top: 0.625rem;
    font-family: ${props => props.theme.fonts.copy};
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;

    @media (max-width: 500px) {
        font-size: 1rem;
    }

    a {
        color: ${props => props.theme.colors.text};

        &:hover {
            color: ${props => props.theme.colors.secondary};
        }
    }
`;

const QuestionAndAnswer = ({ question, answer, numberOfFAQ }) => {

    const [displayedAnswer, setDisplayedAnswer] = useState(false);
    const numberOfExpandedFAQ = useSelector(state => state.displayChanges[0].numberOfExpandedFAQ);
    const dispatch = useDispatch();

    const displayAnswer = () => {
        if (displayedAnswer) {
            setDisplayedAnswer(false);
            dispatch(decrementExpandedFAQ());
        } else {
            setDisplayedAnswer(true);
            dispatch(incrementExpandedFAQ());
        }
    };
    
    numberOfExpandedFAQ === numberOfFAQ && !displayedAnswer ? setDisplayedAnswer(true) : null;
    numberOfExpandedFAQ === 0 && displayedAnswer ? setDisplayedAnswer(false) : null;

    return (
        <QuestionListItem>
            <ExpandCollapseButton onClick={displayAnswer}>
                {question}
                {displayedAnswer ? 
                    <FontAwesomeIcon icon={faMinus} size="1x" color="#A03232" />
                    :
                    <FontAwesomeIcon icon={faPlus} size="1x" color="#A03232" />
                }
            </ExpandCollapseButton>
            {displayedAnswer ? <Answer dangerouslySetInnerHTML={{ __html: answer }}></Answer> : ""}
        </QuestionListItem>
    )
}

export default QuestionAndAnswer;