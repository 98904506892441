import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useDispatch } from 'react-redux';
import { resetExpandedFAQ } from '../actions/displayChanges';
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PostTitle from '../components/Post/PostTitle'
import SEO from '../components/SEO'
import RecoPanelLinks from '../components/Post/RecoSidePanel';
import QuestionAndAnswer from '../components/FAQ/QuestionAndAnswer';
import ExpandCollapseAll from '../components/FAQ/ExpandCollapseAll';
import { updateSearchValue } from '../actions/valueChanges';

const ContentContainer = styled.div`
  display: flex;

  @media (max-width: 790px) {
    flex-wrap: wrap;

    aside {
      overflow: visible;
      padding: 3rem 0 0;
    }
  }
`;

const QuestionList = styled.ul`
  width: 70%;
  font-family: ${props => props.theme.fonts.title};
  padding-right: 1.875rem;

  @media (max-width: 790px) {
    width: 100%;
    padding-right: 0;
  }
`;


const FAQ = ({ data }) => {

  const posts = data.allContentfulPost.edges
  const dispatch = useDispatch();
  const pageTitle = "[ In ]Frequently Asked Questions";
  const faqClass = "faq-title";
  const recoSidePanelTitle = "Start Exploring";

  useEffect(() => {
    dispatch(updateSearchValue(""));
    return () => {
      dispatch(resetExpandedFAQ());
    }
  }, [])

  const faqData = [
    {
      question: "What is Eklectish?",
      answer: "Eklectish is a platform for thoughtful and curious people to re-engage with the internet.",
    },
    {
      question: `What do you mean, "re-engage with the internet?"`,
      answer: "When the internet became available to consumers, the dream was that it would facilitate a global exchange of experiences and ideas. The 80s painted it as a utopia, a digital bridge that could reach all corners of the Earth. It has since become a haven for trolls, trojan horses, marauding slut shamers, and Mark Zuckerberg (species: undefined). In all of this chaos, where can people feel safe to exchange and explore new ideas? Sites with thought-provoking media are behind paywalls and open-source media is a hotbed for misinformation and comment section mudslinging. There are millions of people who have fascinating experiences and stories to tell who would like to share online, but the general negative environment and shockingly limited options have forced many to remain silent. They’re not journalists so they can’t share on the paywalled sites. Sharing content on social media can feel inauthentic or become a tasty snack for trolls. So we built a platform where curiosity is cherished and quality content is not subject to a monthly fee. A place where people can re-engage with the internet, the way it was originally designed to be: an endless world of virtual discovery.",
    },
    {
      question: "Sounds nice, but doesn’t tell me much. So what is Eklectish again?",
      answer: "Sorry got a little lost in our feelings there. Long story short, Eklectish was born over several Zoom calls (Xi Jingping was undoubtedly listening and disapproving) out of an inspiration to create something during the dark days of COVID-19. We discussed how despite having some incredibly insightful conversations with peers over Zoom and instant messaging, we weren’t seeing anything of value on social media or for that matter, mainstream media. We decided to create a platform that encourages thoughtful and curious people to re-engage with the internet. By providing thoughtful people with a chance to engage, through articles, podcasts, and videos, with what otherwise might go unheard, our hope is to create a space with an eclectic mix of content that isn’t divided into sections or siloes; a place where someone can explore something new.",
    },
    {
      question: "Why aren’t there sections on the site?",
      answer: "We believe knowledge is broadened through exposure to different subjects and perspectives. The majority of sites give you content they think you’ll find interesting, but this, in turn, creates a feedback loop that narrows what you end up seeing and consuming. It’s like this - these companies are like an ice cream shop that only serves chocolate. This forces you to always pick the same flavour, reinforcing the owner to only serve chocolate because they think that’s what you prefer. Yet you’d also like vanilla, but because they never give you the option to pick it, they’ll never learn that you want something more. We don’t give you a single flavour, we give you the ice cream, gelato and that sweet sweet sorbet.",
    },
    {
      question: "Cool, cool. So who are these content creators?",
      answer: "Thoughtful people like you! Send us your stuff. We’re happy to have it!",
    },
    {
      question: "Alright, I’m in. Where can I sign up and start posting my TikToks?",
      answer: `Well firstly, there aren’t any profiles or instant uploads, which prevents the reactionary postings that you see on twitter and other message boards. Think of Eklectish as an aggregated blogging site that adheres to journalistic ethics and standards. No flat earth conspiracies here. We believe many people (including ourselves) would benefit from reflecting on our ideas and putting pen to paper to really think them through. Too many ideas that come to mind are immediately vomited on the internet and rarely thought of again before moving on to the next thing. Our content creators are encouraged to write/podcast/vlog about what interests them, and as long as there is quality and good faith in the content, it interests us and will be posted on Eklectish. People from any background or experience can <a href="/contact">publish their ideas on Eklectish</a>, creating a platform for readers to engage with new perspectives and diversify their own interests.`,
    },
    {
      question: "Sounds great! Best of luck. Last question, what’s with the name?",
      answer: "Webster’s english dictionary defines eklectish as … nothing, because it is a word we made up. Obviously derived from the word eclectic, we wanted to evoke the eclectic mix of the site’s content in the name, while taking the pretentious edge off. We used the first ‘k’ to harken back to the word’s ancient Greek roots (not pretentious at all, right?), sandwiched around the modern word, and added the ish for a more contemporary vernacular. So when Googling Eklectish, you will only find us! Unless you’re googling in Germany or Austria where you may find an assortment of sausage houses.",
    },
  ]

  return (
      <Layout>
        <SEO title="FAQ" description="FAQ style to educate users about Eklectish" />
          <Container>
              <PostTitle title={pageTitle} className={faqClass}>Faq</PostTitle>
              <ContentContainer>
                <QuestionList>
                  <ExpandCollapseAll numberOfFAQ={faqData.length}/>
                  {
                    faqData.map(({ question, answer }, i) => {
                      return (
                        <QuestionAndAnswer question={question} answer={answer} numberOfFAQ={faqData.length} key={i} />
                      )
                    })
                  }
                </QuestionList>
                <RecoPanelLinks posts={posts} basePath={""} currentPostId={0} title={recoSidePanelTitle} />
              </ContentContainer>
          </Container>
      </Layout>
  )
}

export default FAQ


export const query = graphql`
  query {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          id
          slug
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            ogimg: resize(width: 1800) {
              src
            }
          }
          authors {
            title
          }
          metaDescription {
            childMarkdownRemark {
              excerpt(pruneLength: 140)
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 120)
            }
          }
        }
      }
    }
  }
`